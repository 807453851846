<template>
    <div>
         <div class="home-pc">
               <div class="wenjuan">
                    <div class="wenjuan-banner">
                        <img src="@/assets/image/banner3.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="wenjuan-ying">
                        <div class="ying-wenzi">
                            <p>应用场景</p>
                            <div class="row1">
                            <div class="col1">
                                <div class="dian-ziti" @mouseenter="gety()" @mouseleave ="getyc()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/ying.png" alt="" v-if="falg ==0">
                                            <img src="@/assets/image/ying12.png" alt="" v-else>
                                            <span>营销方案</span>
                                        </div>
                                        <div class="wen-zi">
                                            自动生成营销宣传推广文案，辅助完成项目方案书，赋能营销团队
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety1()" @mouseleave ="getyc1()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/zhi1.png" alt="" v-if="falg1 ==0">
                                            <img src="@/assets/image/ke12.png" alt="" v-else>
                                            <span>智能客服</span>
                                        </div>
                                        <div class="wen-zi">
                                            智能回复客户的问题，提升客服的专业回复能力，提升客户满意度
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety2()" @mouseleave ="getyc2()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/IT.png" alt="" v-if="falg2 ==0">
                                            <img src="@/assets/image/IT12.png" alt="" v-else>
                                            <span>IT服务</span>
                                        </div>
                                        <div class="wen-zi">
                                            智能自动化处理大量重复、简单的IT问题，提升员工满意度，降低成本
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                            <div class="row1" style="margin-top:30px">
                            <div class="col1">
                                <div class="dian-ziti" @mouseenter="gety3()" @mouseleave ="getyc3()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/ban.png" alt="" v-if="falg3 ==0">
                                             <img src="@/assets/image/ban12.png" alt="" v-else>
                                            <span>办公写作</span>
                                        </div>
                                        <div class="wen-zi">
                                            提供员工日常办公写作所需要的大模型服务，包括提纲、建议内容和翻译等 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety4()" @mouseleave ="getyc4()">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/qi.png" alt="" v-if="falg4 ==0">
                                            <img src="@/assets/image/qi12.png" alt="" v-else>
                                            <span>企业管理</span>
                                        </div>
                                        <div class="wen-zi">
                                            针对于公司各类规章制度和知识内容，一键上传，自动回答员工问题  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti" @mouseenter="gety5()" @mouseleave ="getyc5()">
                                    <div class="ziti-wen" >
                                        <div class="wen-img">
                                            <img src="@/assets/image/ji.png" alt="" v-if="falg5 == 0">
                                            <img src="@/assets/image/ji12.png" alt="" v-else>
                                            <span>技术支持</span>
                                        </div>
                                        <div class="wen-zi">
                                            对于售前、售后过程中碰到的技术相关问题，快速提供解决的指导方案  
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="dayuyan">
                        <div class="dayuyan-wenzi">
                            大语言模型+企业知识库，打造智能员工助手
                        </div>
                        <div class="danyuyan-yijian">
                            一键上传文档，智能分析和学习，结合大语言模型实时给出解答；通过Agent与业务系统结合，精准提供回答
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke3.png" alt="">
                        </div>
                    </div>
                    <div class="zhichi">
                        <div class="zhichi-wenzi">
                            支持模型私有化部署，内容可管可控
                        </div>
                        <div class="zhichi-yijian">
                            提供本地私有大模型的部署、调试和预训练，结合本地知识库，确保内容安全保密
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke4.png" alt="">
                        </div>
                    </div>
                    <div class="duo">
                        <div class="duo-wenzi">
                            多种接入方式，使用方便快捷
                        </div>
                        <div class="duo-yijian">
                           问答窗口提供灵活的嵌入方式，方便在现有的业务系统中，快速打开并进行沟通；提供API，第三方系统可以直接调用并使用问答服务
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke5.png" alt="">
                        </div>
                    </div>
                    <div class="hui">
                        <div class="hui-wenzi">
                            支持语音互动，问答更简便
                        </div>
                        <div class="hui-yijian">
                            结合ASR和TTS的能力，可以直接通过语音与智能问答系统进行交互，无需动手就可以获取所需内容
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke6.png" alt="">
                        </div>
                    </div>
                </div>
        </div>
        <div class="home-yidong">
              <div class="wenjuan">
                    <div class="wenjuan-banner">
                        <img src="@/assets/image/banner3.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="wenjuan-ying">
                        <div class="ying-wenzi">
                            <p style="font-size:20px">应用场景</p>
                            <div class="row1" >
                            <div class="col1">
                                <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/ying.png" alt="">
                                            <span>营销方案</span>
                                        </div>
                                        <div class="wen-zi">
                                            自动生成营销宣传推广文案，辅助完成项目方案书，赋能营销团队
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/zhi1.png" alt="">
                                            <span>智能客服</span>
                                        </div>
                                        <div class="wen-zi">
                                            智能回复客户的问题，提升客服的专业回复能力，提升客户满意度
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/IT.png" alt="">
                                            <span>IT服务</span>
                                        </div>
                                        <div class="wen-zi">
                                            智能自动化处理大量重复、简单的IT问题，提升员工满意度，降低成本
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                                <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/ban.png" alt="">
                                            <span>办公写作</span>
                                        </div>
                                        <div class="wen-zi">
                                            提供员工日常办公写作所需要的大模型服务，包括提纲、建议内容和翻译等 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/qi.png" alt="">
                                            <span>企业管理</span>
                                        </div>
                                        <div class="wen-zi">
                                            针对于公司各类规章制度和知识内容，一键上传，自动回答员工问题  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col1">
                            <div class="dian-ziti">
                                    <div class="ziti-wen">
                                        <div class="wen-img">
                                            <img src="@/assets/image/ji.png" alt="">
                                            <span>技术支持</span>
                                        </div>
                                        <div class="wen-zi">
                                            对于售前、售后过程中碰到的技术相关问题，快速提供解决的指导方案  
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                            
                        </div>
                    </div>
                    <div class="dayuyan">
                        <div class="dayuyan-wenzi">
                            大语言模型+企业知识库，打造智能员工助手
                        </div>
                        <div class="danyuyan-yijian">
                            一键上传文档，智能分析和学习，结合大语言模型实时给出解答；通过Agent与业务系统结合，精准提供回答
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke3.png" alt="" style="width:80%">
                        </div>
                    </div>
                    <div class="zhichi">
                        <div class="zhichi-wenzi">
                            支持模型私有化部署，内容可管可控
                        </div>
                        <div class="zhichi-yijian">
                            提供本地私有大模型的部署、调试和预训练，结合本地知识库，确保内容安全保密
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke4.png" alt="" style="width:80%">
                        </div>
                    </div>
                    <div class="duo">
                        <div class="duo-wenzi">
                            多种接入方式，使用方便快捷
                        </div>
                        <div class="duo-yijian">
                           问答窗口提供灵活的嵌入方式，方便在现有的业务系统中，快速打开并进行沟通；提供API，第三方系统可以直接调用并使用问答服务
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke5.png" alt="" style="width:80%">
                        </div>
                    </div>
                    <div class="hui">
                        <div class="hui-wenzi">
                            支持语音互动，问答更简便
                        </div>
                        <div class="hui-yijian">
                            结合ASR和TTS的能力，可以直接通过语音与智能问答系统进行交互，无需动手就可以获取所需内容
                        </div>
                        <div style="text-align:center;margin-top:50px">
                            <img src="@/assets/image/ke6.png" alt="" style="width:80%">
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            falg:0,
            falg1:0,
            falg2:0,
            falg3:0,
            falg4:0,
            falg5:0,
        }
    },
    methods:{
        gety(){
            this.falg = 1
        },
        getyc(){
            this.falg = 0
        },
        gety1(){
            this.falg1 = 1
        },
        getyc1(){
            this.falg1 = 0
        },
        gety2(){
            this.falg2 = 1
            console.log(this.falg)
        },
        getyc2(){
            this.falg2 = 0
        },
        gety3(){
            this.falg3 = 1
            console.log(this.falg)
        },
        getyc3(){
            this.falg3 = 0
        },
        gety4(){
            this.falg4 = 1
            console.log(this.falg)
        },
        getyc4(){
            this.falg4 = 0
        },
        gety5(){
            this.falg5 = 1
            console.log(this.falg)
        },
        getyc5(){
            this.falg5 = 0
        }
    }
}
</script>
<style lang="scss">
    .home-pc{
        .wenjuan{
        .wenjuan-ying{
            height: 550px;
            background-color: #DCEBFF;
            .ying-wenzi{
                padding-top: 40px;
                p{
                    font-size: 40px;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 30px;
                }
                .row1{
                        width: 1270px;
                        display: flex;
                        justify-content: space-between;
                        margin: 0 auto;
                       .dian-ziti:hover{
                            background-color: #0E76FF;
                            cursor: pointer;
                            span{
                                color: #fff !important;
                            }
                            .wen-zi{
                                color: #C3DDFF !important;
                            }
                        }
                    .dian-ziti{
                        width: 400px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .ziti-wen{
                            padding: 0 15px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .wen-img{
                                display: flex;
                                align-items: center;
                            }
                            span{
                                font-size: 18px;
                                color: #000000;
                                margin-left: 10px;
                            }
                        }
                        .wen-zi{
                            margin-top: 10px;
                            font-size: 18px;
                            color: #919191;
                        }
                    }
                }
                .ying-dian1{
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    .dian-ziti{
                        width: 400px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        margin-right: 40px;
                    }
                }
            }
        }
        .dayuyan{
                height: 690px;
                background-color: #F5FCFF;
                .dayuyan-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .danyuyan-yijian{
                    font-size: 20px;
                    color: #262A47;
                    text-align: center;
                    margin-top: 20px;
                }
         }
         .zhichi{
             height:800px;
             background-color: #DCEBFF;
                .zhichi-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .zhichi-yijian{
                    font-size: 20px;
                    color: #262A47;
                    text-align: center;
                    margin-top: 20px;
                }
         }
        .duo{
                height: 600px;
                background-color: #F5FCFF;
                .duo-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .duo-yijian{
                    font-size: 20px;
                    color: #262A47;
                    text-align: center;
                    margin-top: 20px;
                }
         }
            .hui{
             height:780px;
             background-color: #DCEBFF;
                .hui-wenzi{
                    font-size: 40px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .hui-yijian{
                    font-size: 20px;
                    color: #262A47;
                    text-align: center;
                    margin-top: 20px;
                }
         }
      }
    }
    .home-yidong{
        .wenjuan{
        .wenjuan-ying{
            height: auto !important;
            background-color: #DCEBFF;
            padding-bottom: 30px;
            .ying-wenzi{
                padding-top: 20px;
                p{
                    font-size: 14px;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 20px;
                }
                .row1{
                    display: flex;
                    justify-content: center;
                    .dian-ziti{
                        width: 152px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        margin-right: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-bottom: 20px;
                        .ziti-wen{
                            padding: 0 15px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .wen-img{
                                display: flex;
                                align-items: center;
                            }
                            span{
                                font-size: 16px;
                                color: #000000;
                                margin-left: 10px;
                            }
                        }
                        .wen-zi{
                            margin-top: 10px;
                            font-size: 14px;
                            color: #919191;
                        }
                    }
                }
                .ying-dian1{
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    .dian-ziti{
                        width: 400px;
                        height: 167px;
                        background: #FFFFFF;
                        border: 1px solid #E9F0F3;
                        border-radius: 10px;
                        margin-right: 40px;
                    }
                }
            }
        }
        .dayuyan{
                height: 350px;
                background-color: #F5FCFF;
                .dayuyan-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .danyuyan-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;
                    margin-top: 20px;
                }
         }
         .zhichi{
             height:390px;
             background-color: #DCEBFF;
                .zhichi-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .zhichi-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;
                    margin-top: 20px;
                }
         }
        .duo{
                height: 380px;
                background-color: #F5FCFF;
                .duo-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .duo-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;;
                    margin-top: 20px;
                }
         }
            .hui{
             height:460px;
             background-color: #DCEBFF;
                .hui-wenzi{
                    font-size: 18px;
                    color: #262A47;
                    text-align: center;
                    padding-top: 40px;
                }
                .hui-yijian{
                    font-size: 14px;
                    color: #262A47;
                    margin:0 20px;
                    line-height: 22px;
                    margin-top: 20px;
                }
         }
      }
    }

</style>